import React, { useState, useEffect } from 'react'
import ArticleCardFixed from '../components/ArticleCardFixed';

import './LatestPosts.css';

const LatestPosts = ( {posts, title, onClick, pageTop} ) => {

    const [displayCount, setDisplayCount] = useState(8);
    const [showMoreVisible, setShowMoreVisible] = useState(false);
    const [displayPosts, setDisplayPosts] = useState([]);
    const [topPad, setTopPad] = useState(20)

    // Update display posts when posts change or display count changes
    useEffect(() => {
        if(displayCount >= posts.length){
            setDisplayPosts(posts);
        } else {
            setDisplayPosts(posts.slice(0, displayCount));
            setShowMoreVisible(true);
        }
    }, [posts, displayCount]); 

    const handleShowMore = () => {
        //console.log("handleShowMore: " + displayCount + " >= " + posts.length)
        if(displayCount + 8 >= posts.length){
            setShowMoreVisible(false);
        }
        setDisplayCount(displayCount + 8);
    }


  return (
    <div className={`latest-posts__section ${pageTop ? 'topPad' : ''}`} >
        <p className='latest-posts_title'>{title}</p>
        <div className='latest-posts__articles'>
            {displayPosts.map(post => (
                <div key={post._id+"latest"}  className='latest-posts__article-wrapper'>
                    <ArticleCardFixed post={post} onClick={onClick} />
                </div>
            ))}
        </div>
        {showMoreVisible && <p className='show-more' onClick={handleShowMore}>Show More</p>} 
    </div>
  )
}

export default LatestPosts
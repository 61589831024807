import React, { useState } from 'react';
import './Contact.css';
import 'aos/dist/aos.css';
import { BASE_URL, urlPostfix } from '../constants';
import NewsletterSignup from '../sections/NewsletterSignup';
import axios from 'axios';

const Contact = ({ landing }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageEmail, setErrorMessageEmail] = useState("");
    const [errorMessagePhone, setErrorMessagePhone] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleOnSubmit = () => {
        const name = document.getElementById('nameInput').value;
        const phoneNumber = document.getElementById('phone-number_input').value;
        const email = document.getElementById('email-input').value;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const message = document.getElementById('message').value;
        
        // console.log(phoneNumber);
        // console.log(email);

        if(phoneNumber.length < 12){
            setErrorMessagePhone("**Use Format: 000-000-0000");
            return;
        }
        else{
            setErrorMessagePhone("");
        }

        if (!emailPattern.test(email)) {
            console.error("Invalid email format");
            setErrorMessageEmail("**Invalid email address");
            return; // Exit the function if email format is invalid
        } else {
            setErrorMessageEmail("");
        }
        if(message.length == 0){
            setErrorMessageBody("**Message cannot be blank")
            return;
        } else {
            setErrorMessageBody("");
        }

        const packageBody = {
            "name": name,
            "phoneNumber": phoneNumber,
            "email": email,
            "message": message,
            "newSubmission": true,
            "archived": false
        };

        axios.post(`${BASE_URL}/contactsubmission/add${urlPostfix}`, packageBody)
        .then(response => {
            setSuccessMessage(`Your message has been submitted.\nWe will review and get in touch soon!`);
            setErrorMessageEmail("");
            setErrorMessagePhone("");
            setErrorMessageBody("");
            setErrorMessage("");
        })
        .catch(error => {
            console.error("Error submitting message:", error);
            setErrorMessage("Error, something went wrong...");
        });
    };

    const handlePhoneInputChange = (event) => {
        event.target.value = addSpaces(event.target.value);
    };

    function addSpaces(initial){
        if(initial.length == 3){
            initial = initial + "-";
        }
        else if(initial.length == 7)
        initial = initial + "-";
        return initial;
    };

    return (
        <div style={{position: "relative"}}>
            <div className={landing ? 'contact__section' : 'contact__section top_padding-100'}>
                <h1 className='contact_header' data-aos="fade-up">Contact Me Directly</h1>
                {!successMessage && <form onSubmit={handleOnSubmit} className='contact-form' data-aos="fade-up">
                    <input type='text' id='nameInput' placeholder='Name' className='name_input contact-form_inputs' />
                    <input type='tel' id='phone-number_input' placeholder='Phone Number: 000-000-0000' className='phone-number_input contact-form_inputs' pattern="[0-9]{3} [0-9]{3} [0-9]{4}" onChange={handlePhoneInputChange} maxLength="12"/>
                    {errorMessagePhone && <p className='form-error'>{errorMessagePhone}</p>}
                    <input type='email' id='email-input' placeholder='Email' className='email_input contact-form_inputs' />
                    {errorMessageEmail && <p className='form-error'>{errorMessageEmail}</p>}
                    <textarea id="message" className="message_text-area contact-form_inputs" placeholder="Enter your message here..."></textarea>
                    {errorMessageBody && <p className='form-error'>{errorMessageBody}</p>}
                    <button type="submit" className='contact-form_submit' onClick={handleOnSubmit}>Submit</button>
                    {errorMessage && <p className='form-error'>{errorMessage}</p>}
                </form>}
                {successMessage && <div className='form-success-box'><p className='form-success'>{successMessage}</p></div>}
            </div>
            <NewsletterSignup className='newsletter_section' />
        </div>
    )
}

export default Contact
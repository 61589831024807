import React from 'react';
import './Landing.css';
import 'aos/dist/aos.css';
import NewsletterSignup from '../sections/NewsletterSignup';
import hero from '../media/client3.jpeg';
import client from '../media/client2-mod-affinity.png';
import { Link } from 'react-router-dom';
import logoLong from '../media/logo-long.svg'
// import tennisTraining from '../media/Tennis_Training.png'
import Contact from './Contact';
import Faq from '../sections/Faq';

const Landing = () => {
  return (
    <>
    {/* <div className='landing-gray-background-top'></div> */}
    <div className='landing__page'>
        <div className='landing-hero'>
            <div className='hero-box'>
                <img src={hero} className='landing-hero-hero' data-aos="fade-down"/>
            </div>
            <div className='hero-text'  data-aos="fade-up">
                <span>Meet Doctor </span>
                <Link to='/about/who-we-are' className='hero-link-cta'>
                    <span className='hero-text-john'>John </span>
                </Link>
                <span>A New Type of</span>
                <span>Therapist</span>
            </div>
        </div>
        <div className='come-to-you'>
            <div className='landing-gray-background-mid'></div>
            <img src={logoLong} alt='ReImagine PT logo' className='client-therapy-image-logo'  data-aos="fade-up"/>
            <img src={client} alt='PR with client' className='client-therapy-image'  data-aos="fade-up"/>
        </div>
        
        <div className='landing-rethink' >
            <div className='hero-text'  data-aos="fade-up">
                <span className='landing-text'>Re-Learn how to Train</span>
                <span className='landing-text'>With Expert Advice</span>
                <Link to='/blog' className='rethink-link-cta'>
                    <div className='landing-rethink-button'>
                        <span>Start Learning</span>
                    </div>
                </Link>
            </div>
            {/* <img src={tennisTraining} className='landing-rethink-tennis-training'  data-aos="fade-up"/> */}
            <div className='landing-gray-background-mid2'></div>
        </div>
        <div className='faq-box' data-aos="fade-up">
            <Faq/>
        </div>
    </div>
    
    
    <Contact landing={true} />
    </>
  )
}

export default Landing
import React from 'react';
import './Faq.css';

const Faq = () => {
    const faqs = [
        {
            question: "How Far Will You Travel?",
            answer: "I provide mobile physical therapy services in the Colorado Springs area, traveling to your home for your convenience. This allows you to receive personalized, one-on-one care in the comfort of your own space, without any distractions from other clients or administrative tasks."
        },
        {
            question: "Why Are You Out-Of-Network?",
            answer: "I choose to operate out-of-network to give you and me the freedom to create a treatment plan tailored specifically to your needs, without restrictions from insurance companies. This ensures that we can focus solely on your recovery and wellbeing, without having to worry about insurance limitations"
        },
        {
            question: "What Are Your Specialties?",
            answer: "I work with tennis, pickleball, and other racket sports players to help them recover from injury and improve performance."
        },
        {
            question: "What Are Your Rates?",
            answer: "For a single session it is $175. I also provide package deals at $165 per session for 6 sessions ($990) and $155 per session for 12 sessions or more ($1860)"
        }
    ];

    return (
        <div className='faq__section '>
            <div className='faq-accordion '>
                <div className="accordion" id="accordionExample">
                    {faqs.map((faq, index) => (
                        <div className="accordion-item dark-mode" key={index}>
                            <h2 className="accordion-header">
                                <button className={`accordion-button ${index === 0 ? 'dark-mode-header' : 'collapsed dark-mode-header'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index + 1}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`collapse${index + 1}`}>
                                    {faq.question}
                                </button>
                            </h2>
                            <div id={`collapse${index + 1}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {faq.answer}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Faq;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './BlogPost.css';
import avatar from '../media/dr_john.png'
import NewsletterSignup from '../sections/NewsletterSignup';
import { drJohnBio_short } from '../text'
import PopularPosts from '../sections/PopularPosts';
import { BASE_URL } from '../constants';
import { postfixImageWidth } from '../util';

const AutorBio = () => {
    return (
        <div className='author-bio__section'>
            <img src={avatar} alt='Author' />
            <p>{drJohnBio_short}</p>
        </div>
    )
}

export const PostSection = ({ post }) => {
    let formattedDate = "MM-DD-YYYY";

    const date =new Date(post.date);
        formattedDate = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

    return (
        <>
            <h1 className='blog-post_title margin-x'>{post.title}</h1>
            <div className='blog-post_author margin-x'>
                <img src={avatar} alt='Author' />
                <h4>{post.author}</h4>
            </div>
            <h6 className='blog-post_date margin-x'>{formattedDate}</h6>  
                <img src={`${BASE_URL}${postfixImageWidth(post.thumbnail,"1200")}`} alt='article cover' className='blog-post_cover'/>
                <div className='post-content blog-post_padding' dangerouslySetInnerHTML={{ __html: post.content }} />
            <hr className='blog-post_divider'></hr>
        </>
    )
};

const BlogPost = ({ post, posts, onClick, loadArticle }) => {

    const { PostTitle } = useParams();
    
    if(post === undefined){
        loadArticle(PostTitle);
        return (<></>);
    } else {
        if(post.title.includes("loading")){
          console.log("loadArticle");
          loadArticle(PostTitle);
        };


        return (
            <>
                <div className='blog-post__section topPad'>
                    <div className='blog-post_box'>
                        {/* <CardTag category={post.category}/> */}
                        <PostSection post={post}/>
        
                        <div className='blog-post_padding'>
                            <AutorBio/>
                        </div>
                        <PopularPosts posts={posts} background={false} onClick={onClick}  />
                    </div>
                </div>
                <NewsletterSignup/>
            </>
          )
    }
}

export default BlogPost;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const urlPostfix = process.env.REACT_APP_URL_POSTFIX;


//colors
export const GREEN = "#66bb6a";
export const BLUE = "#64b5f6";
export const PURPLE = "#ba68c8";
export const RED = "#ff5252";

export const youtube_url = 'https://www.youtube.com/@dr.johnfischer';
export const instagram_url = 'https://www.instagram.com/dr.johnfischer?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr';
export const facebook_url = 'https://www.facebook.com/profile.php?id=61550304324073';
export const linkedin_url = 'https://www.linkedin.com/in/john-fischer-48699629a/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app';
export const tiktok_url = 'https://www.tiktok.com/@dr.johnfischer?_t=8hnfo8cyVXm&_r=1';

export const blog_subcat = ["sports_and_recreation", "fitness", "health", "rehab"];
import React from 'react'
import LatestPosts from '../sections/LatestPosts'
import NewsletterSignup from '../sections/NewsletterSignup';
import './BlogSearch.css';
import { getCategoryFormat } from '../util';

const BlogSearch = ({ posts, category, onClick }) => {

    const filteredPosts = posts.filter(post => post.category === category);

  return (
    <>
        <div className='blog-search__section'>
            <LatestPosts posts={filteredPosts} title={getCategoryFormat(category)} onClick={onClick} pageTop={true}/>
        </div>
        <NewsletterSignup />
    </>
  )
}

export default BlogSearch
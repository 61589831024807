import React from 'react';
import { BLUE, GREEN, RED, PURPLE } from '../constants';
import './CardTag';
import { getCategoryFormat } from '../util';

const CardTag = ({category}) => {

    const getCategoryColor = (category) => {
        switch (true) {
            case category?.includes("sports"):
                return PURPLE;
            case category?.includes("fitness"):
                return BLUE;
            case category?.includes("rehab"):
                return RED;
            case category?.includes("health"):
                return GREEN;
            default:
                return "black"; // Default color if none of the conditions are met
        }
    };

  return (
    <div className='card-tag'>
        <span style={{
            color: getCategoryColor(category), 
            border: `2px solid ${getCategoryColor(category)}`,
            padding: "4px 12px",
            display: "inline-block",
            borderRadius: "50px",
            fontWeight: "bold",
            backgroundColor: "#3a3a3abd"
        }}>{getCategoryFormat(category)}</span>
    </div>
  )
}

export default CardTag
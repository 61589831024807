import React from 'react';
import { BASE_URL } from '../constants';
import { Link } from 'react-router-dom'
import './PopularPosts.css';
import { postfixImageWidth, stringToUrl } from '../util';


const PopularPosts = ({ posts, background, onClick }) => {

    if (!posts || posts.length === 0) {
        return null; 
      }

    const handleClick = (urlTitle) => {
        onClick(urlTitle); // Pass the _id to the onClick function
    };

  return (
    <div className={`popular-posts__section ${background ? 'background' : ''}`}>
    <hr className='horizontal-divider' />
        <span className='popular-posts_title'>Popular Posts</span>
        <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-indicators">
                {posts.map((post, index) => (
                    <button 
                    key={index} 
                    type="button" 
                    data-bs-target="#carouselExampleCaptions" 
                    data-bs-slide-to={index} 
                    className={index === 0 ? "active" : ""} 
                    aria-current={index === 0 ? "true" : "false"} 
                    aria-label={`Slide ${index + 1}`}
                    ></button>
                ))}
            </div>
            <div className="carousel-inner" >
                {posts.map((post, index) => (
                <div key={post._id} className={`carousel-item ${index === 0 ? 'active' : ''}`} onClick={() => handleClick(stringToUrl(post.title))} >
                    <Link to={`/blog/post/${stringToUrl(post.title)}`} className='blog-link'>
                        <h4 className='small-title gray-backdrop'  >{post.title}</h4>
                        <img src={`${BASE_URL}${postfixImageWidth(post.thumbnail, "1200")}`} className="d-block w-100" alt="Article Image"/>
                        <div className="carousel-caption d-none d-md-block">
                            <p className='gray-backdrop'>{post.subtitle}</p>
                        </div>
                    </Link>
                </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    </div>
  )
}

export default PopularPosts
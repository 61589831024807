import React, {useState, useEffect} from 'react';
import { BASE_URL, urlPostfix } from './constants';
import axios from 'axios';
import AOS from "aos";
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Landing from './pages/Landing'
import Blog from './pages/Blog'
import { WhoWeAre, WhatWeDo} from './pages/About'
import Contact from './pages/Contact'
import Footer from './components/Footer'
import BlogSearch from './pages/BlogSearch';
import BlogPost from './pages/BlogPost';
import { stringToUrl } from './util';



function App() {
    const [posts, setPosts] = useState([]); 
    const [currentPost, setCurrentPost] = useState({
        "_id": {
          "$oid": "..."
        },
        "author": "loading",
        "date": "...",
        "title": "loading",
        "subtitle": "...",
        "thumbnail": "/frontend/media/...",
        "content": "...",
        "category": "sports-and-recreation",
        "popular": false,
        "main": false,
        "sponsored": false,
        "thumbnailOffset": 0
      });

    useEffect(() => {
        //console.log("Getting posts from : " + `${BASE_URL}/post/`);
        axios.get(`${BASE_URL}/post/${urlPostfix}`)
            .then(response => {
                if(response.data.length > 0){
                    const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setPosts(sortedData);
                    //console.log("Blog response.data.length " + response.data.length);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    useEffect(() =>{
        AOS.init({
            duration: 1000
        });
        AOS.refresh();
    });

    const getCurrentPost = (urlTitle) => {
        //console.log(`App.jsx post: ${_id}`)
        setCurrentPost(posts.find(post => stringToUrl(post.title) === urlTitle))
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  return (
    <> 
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/blog" element={<Blog posts={posts} onClick={getCurrentPost}/>}/>
                <Route path="/blog/sports-and-recreation" element={<BlogSearch  posts={posts} category={'sports-and-recreation'} onClick={getCurrentPost}/>}/>
                <Route path="/blog/fitness" element={<BlogSearch posts={posts} category={'fitness'} onClick={getCurrentPost}/>}/>
                <Route path="/blog/health" element={<BlogSearch posts={posts} category={'health'} onClick={getCurrentPost}/>}/>
                <Route path="/blog/rehab" element={<BlogSearch posts={posts} category={'rehab'} onClick={getCurrentPost}/>}/>
                <Route path="/about/who-we-are" element={<WhoWeAre/>}/>
                <Route path="/about/what-we-do" element={<WhatWeDo/>}/>
                <Route path="/contact-us" element={<Contact/>}/>

                {/* Dynamic route for individual blog posts */}
                <Route path="/blog/post/:postTitle" element={<BlogPost post={currentPost} posts={posts.filter(entry => entry.main === true)} onClick={getCurrentPost} loadArticle={getCurrentPost} />} />
            </Routes>
        </BrowserRouter>
        <Footer>

        </Footer>
        <div className='app-background'></div>
    </>
  );
}

export default App;

import React, {useState} from 'react';
import 'aos/dist/aos.css';
import { BASE_URL, urlPostfix } from '../constants';
import './NewsletterSignup.css';
import axios from 'axios';

const NewsletterSignup = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubscribeClick = () => {
        const email = document.getElementById('emailInput').value;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(email)) {
            console.error("Invalid email format");
            setErrorMessage("**Invalid email format");
            return;
        }

        const packageBody = { "email": email};

        axios.post(`${BASE_URL}/newsletter/add${urlPostfix}`, packageBody)
        .then(response => {
            //console.log(email + " subscribed successfully:", response.data);
            setSuccessMessage(email + " Subscribed!");
            setErrorMessage("");
            document.getElementById('emailInput').value = "";
        })
        .catch(error => {
            console.error("Error subscribing email:", error);
            setErrorMessage("Error, something went wrong...");
        });
    };

  return (
    <div className='newsletter__section'>
        <h1 className='newsletter-title' data-aos="fade-up">Fear of Missing Out?</h1>
        <h1 className='newsletter-title' data-aos="fade-up">Sign up for our Newsletter!</h1>
        <div className='divider' data-aos="fade-up"/>
        <h3 className='newsletter-subtitle' data-aos="fade-up">Keep up with the latest information and advice.</h3>
        {!successMessage && <div className='newsletter-input-button-box' data-aos="fade-up">
            <input type="email" id='emailInput' placeholder="Enter your email" className="newsletter-input" />
            <button className="newsletter-button" onClick={handleSubscribeClick}>Subscribe</button>
        </div>}
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
        {successMessage && <div className='success-message-box'><p className="success-message">{successMessage}</p></div>} {/* Display success message */}
    </div>
  );
};

export default NewsletterSignup
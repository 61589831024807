import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../media/logo.svg'
import logoLong from '../media/logo-long.svg';
import './Navbar.css'

const Navbar = () => {

    const location = useLocation();
    const category_path = location.pathname;
    const [navbarExpanded, setNavbarExpanded] = useState(false);

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setNavbarExpanded(!navbarExpanded);
    };

  return (
    <div>
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top" data-bs-theme="dark">
    <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={handleLinkClick}>
            <img src={logo} alt="ReImagine PT logo" className='navbar-logo'/>
            <img src={logoLong} alt="ReImagine PT logo" className='navbar-logo-compressed'/>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={navbarExpanded} aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse menu-box ${navbarExpanded ? '' : 'collapse'}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                <li className="nav-item">
                    <Link className={`nav-link ${category_path === "/" ? "yellow-link" : ""}`} to="/"  onClick={handleLinkClick}>Home</Link> 
                </li>

                <li className="nav-item">
                    <Link className={`nav-link ${category_path.startsWith("/blog") ? "yellow-link" : ""}`} to="/blog" onClick={handleLinkClick}>Blog</Link> 
                </li>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-subcategory-ham">
                        <li className="nav-item">
                            <Link className={`nav-link  ${category_path.startsWith("/about/sports-and-recreation")  ? "yellow-link" : ""}`} to="/blog/sports-and-recreation" onClick={handleLinkClick}>Sports & Recreation</Link> 
                        </li> 
                        <li className="nav-item">
                            <Link className={`nav-link  ${category_path.startsWith("/blog/fitness")  ? "yellow-link" : ""}`} to="/blog/fitness" onClick={handleLinkClick}>Fitness</Link> 
                        </li> 
                        <li className="nav-item">
                            <Link className={`nav-link  ${category_path.startsWith("/blog/health")  ? "yellow-link" : ""}`} to="/blog/health" onClick={handleLinkClick}>Health</Link> 
                        </li> 
                        <li className="nav-item">
                            <Link className={`nav-link  ${category_path.startsWith("/blog/rehab")  ? "yellow-link" : ""}`} to="/blog/rehab" onClick={handleLinkClick}>Rehab</Link> 
                        </li> 
                    </ul>
                <li className="nav-item">
                    <Link className={`nav-link ${category_path.startsWith("/about/")  ? "yellow-link" : ""}`} to="/about/who-we-are" onClick={handleLinkClick}>About</Link> 
                </li>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-subcategory-ham">
                        <li className="nav-item">
                            <Link className={`nav-link  ${category_path.startsWith("/about/who-we-are")  ? "yellow-link" : ""}`} to="/about/who-we-are" onClick={handleLinkClick}>Who We Are</Link> 
                        </li> 
                        <li className="nav-item">
                            <Link className={`nav-link  ${category_path.startsWith("/about/what-we-do")  ? "yellow-link" : ""}`} to="/about/what-we-do" onClick={handleLinkClick}>What We Do</Link> 
                        </li> 
                    </ul>
                <li className="nav-item">
                    <Link className={`nav-link ${category_path.startsWith("/contact-us")  ? "yellow-link" : ""}`} to="/contact-us" onClick={handleLinkClick}>Contact Us</Link> 
                </li>
            </ul>


            {category_path == "/" && <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-subcategory">
                <li className="nav-item">
                    <Link className={`nav-link yellow-link`} to="/" onClick={handleLinkClick}>ReImagine Physical Therapy</Link> 
                </li> 
            </ul>}
            {category_path.startsWith("/blog") && <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-subcategory">
                <li className="nav-item">
                    <Link className={`nav-link  ${category_path.startsWith("/blog/sports-and-recreation")  ? "yellow-link" : ""}`} to="/blog/sports-and-recreation" onClick={handleLinkClick}>Sports & Recreation</Link> 
                </li> 
                <li className="nav-item">
                    <Link className={`nav-link  ${category_path.startsWith("/blog/fitness")  ? "yellow-link" : ""}`} to="/blog/fitness" onClick={handleLinkClick}>Fitness</Link> 
                </li> 
                <li className="nav-item">
                    <Link className={`nav-link  ${category_path.startsWith("/blog/health")  ? "yellow-link" : ""}`} to="/blog/health" onClick={handleLinkClick}>Health</Link> 
                </li> 
                <li className="nav-item">
                    <Link className={`nav-link  ${category_path.startsWith("/blog/rehab")  ? "yellow-link" : ""}`} to="/blog/rehab" onClick={handleLinkClick}>Rehab</Link> 
                </li> 
            </ul>}
            {category_path.startsWith("/about") && <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-subcategory">
                <li className="nav-item">
                    <Link className={`nav-link  ${category_path.startsWith("/about/who-we-are")  ? "yellow-link" : ""}`} to="/about/who-we-are" onClick={handleLinkClick}>Who We Are</Link> 
                </li> 
                <li className="nav-item">
                    <Link className={`nav-link  ${category_path.startsWith("/about/what-we-do")  ? "yellow-link" : ""}`} to="/about/what-we-do" onClick={handleLinkClick}>What We Do</Link> 
                </li> 
            </ul>}
            {category_path.startsWith("/contact-us") && <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-subcategory">
                <li className="nav-item">
                    <Link className="nav-link yellow-link" to="/contact" onClick={handleLinkClick}>General Query</Link> 
                </li> 
            </ul>}
        </div>
    </div>
    </nav>
  </div>
  )
}

export default Navbar
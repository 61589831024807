import React from 'react';
import './About.css';
import 'aos/dist/aos.css';
import drJohn from '../media/dr_john.png';
import NewsletterSignup from '../sections/NewsletterSignup';
import { drJohnBio, WhatWeDoText } from '../text';

const WhoWeAre = () => {
  return (
    <>
        <div className='about__section topPad'>
            <div className='who-we-are_box'>
                <h1 className='about__header'  data-aos="fade-down">Our Team</h1>
                <div className='about__dr-box' data-aos="fade-up">
                    <div className='dr-name-pic'>
                        <h2 className=''>Dr. John Fischer DPT</h2>
                        <img src={drJohn} />
                        <div className='contact-info'>
                            <p>Contact Information:</p>
                            <p>John.Fischer@ReImaginePT.com</p>
                            <p>(719) 237-9805</p>
                        </div>
                    </div>
                    <div className='dr-bio'>
                        {drJohnBio.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <NewsletterSignup className='newsletter_section' />
    </>
  )
}

const WhatWeDo = () => {
    return(
        <>
            <div className='about__section about__section__what-we-do topPad'>
                <h1 className='about__header' data-aos="fade-down">Physical Therapy Services</h1>
                <div className='what-we-do__box'  data-aos="fade-up">
                    <WhatWeDoText/>
                </div>
            </div>
            <NewsletterSignup className='newsletter_section' />
        </>
    )
}

export { WhoWeAre, WhatWeDo };
export const drJohnBio = "Hi, my name is John. \n My purpose \nI became a physical therapist because I have seen the benefit of pursuing a healthful lifestyle and wanted to help and enable others to achieve their goal of the same. Fitness and a high-quality diet are paramount when it comes to achieving our health goals, and they are ultimately what allows us to be active doing the things we love. Unfortunately, injuries can sometimes derail us from our pursuit of a better life, I know this all too well from my years in the weight room and playing sports. I started this blog to provide my insights into common conditions which inhibit our ability to be active, as well as to provide helpful tips and information regarding a healthful lifestyle. I started this business to work with tennis and racket sport players as well as those looking to be more active, helping them continue to be able to do the things they love and promote their longevity.";

export const drJohnBio_short = "John Fischer is a physical therapist, is driven by his passion for promoting a healthy lifestyle and assisting others in achieving their wellness goals. With a background in Biomedical Science from The University of Colorado Colorado Springs and a Doctorate in Physical Therapy from Rocky Mountain University of Health Professions, he aims to provide valuable insights and tips through his blog to address common injuries that hinder physical activity. He works with tennis & racket sport players and those looking to improve their fitness and longevity in a balanced manner, offering services such as joint and spinal mobilization, soft tissue mobilization and scraping, exercise prescription and progressions, and dry-needling."

export const WhatWeDoText = () => {
    return(
        <>
            <p>If your idea of a positive physical therapy experience is one-on-one with a physical therapist (no aides, no techs, no PTAs), and with the same physical therapist every single session, then you have come to the right place.</p>
            <p>I run a mobile physical therapy business in the Colorado Springs area, meaning that I will travel to your home for your convenience. Because I travel to you, you will have all of my attention, no distractions such as other clients or documentation that needs doing, you are my only priority.</p>
            <h4>What to expect:</h4>
            <p>The initial visit will consist of an in-depth evaluation in order to determine the underlying cause of your symptoms as well as any associated impairments. Each follow-up visit will serve to address the impairments and to progress with treatment as appropriate. A comprehensive home exercise program will then be provided in order to complement what is done during each session, this will be progressed throughout the course of treatment in order to maximize the benefits.</p>
            <h4>Services offered:</h4>
            <p>Joint mobilization and manipulation, spinal manipulation, soft tissue mobilization, exercise prescription, and dry needling.</p>
            <p>I am out-of-network with all insurance companies, meaning that they may not cover the services provided. However, even though I am out of network, depending on your insurance’s out-of-network benefits, they may still reimburse you a portion of the cost if your deductible has been met. Upon request, a superbill describing the physical therapy treatments performed can be provided, you may then submit this to your insurance company. </p>
            <p>Why am I out of network? Because insurance companies shouldn’t reserve the right to dictate how your treatment is carried out. We decide on a plan of care, and then we do it, no need to worry about what treatments your insurance covers, how many visits they allow, etc. In the state of Colorado, you have direct access to physical therapy, you won’t need a referral from your doctor. If anything comes up other than the initial reason you sought physical therapy, we can deal with it then and there, no need to see your doctor and get a new referral so insurance will cover it.</p>
            <p>I accept cash, credit card, and HSA (health savings account)</p>
            <h4>Rates</h4>
            <ul>
                <li>1 Session: $175</li>
                <li>6 Sessions: $165 per session ($990)</li>
                <li>12 Sessions: $155 per session ($1860)</li>
            </ul>
            <p>If you would like more information, feel free to text or call me at (719) 237-9805. You can also send me an email at John.Fischer@ReImaginePT.com</p>
        </>
    );
};
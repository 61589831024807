import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import 'aos/dist/aos.css';
import './ArticleCardHorizontal.css';
import CardTag from './CardTag';
import { BASE_URL } from '../constants';
import { postfixImageWidth, stringToUrl } from '../util';

const ArticleCardHorizontal = ({post, onClick}) => {
    // console.log(post);
    // console.log("Article Card");
    // console.log(typeof post.date)

    const date = new Date(post.date)
    const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });

    const handleClick = () => {
        onClick(stringToUrl(post.title)); // Pass the _id to the onClick function
    };

  return (
    <div className='container' onClick={handleClick}>
    <Link to={`/blog/post/${stringToUrl(post.title)}`} className='blog-link' data-aos="fade-left">
        <div className='article-card-horizontal'>
            <div className='article-card-horizontal_image-box'>
                
                <img src={`${BASE_URL}${postfixImageWidth(post.thumbnail, "800")}`} className="article-card-horizontal_image" alt="article image"/>
                <div className='category-tag'>
                    <CardTag category={post.category} />
                </div>
            </div>
            <div className="card-body">
                <h5 className="article-card-horizontal_date">{formattedDate}</h5>
                <h5 className="article-card-horizontal_title">{post.title}</h5>
                <hr/>
                <p className="article-card-horizontal_text">{post.subtitle}</p>
                <div className='article-card-horizontal_tag'></div>
            </div>
            
        </div>
    </Link>
    </div>
  )
}

export default ArticleCardHorizontal
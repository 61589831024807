import React, { useState } from 'react';
import ArticleCard from '../components/ArticleCard';
import ArticleCardFixed from '../components/ArticleCardFixed'
import ArticleCardHorizontal from '../components/ArticleCardHorizontal';
import './MainPosts.css'

const MainPosts = ({ posts, onClick }) => {
    // console.log("MainPosts");
    // console.log(`posts.length = ${posts.length}`)

    if (!posts || posts.length === 0) {
        return null; // Render nothing if props.posts doesn't exist or is empty
    }

    return (
        <div className='main-posts__section'>
            <div className='main-posts__articles full'>
                <ArticleCard className='article1' post={posts[0]} onClick={onClick} />
                <div className='main-post__side-articles'>
                    <ArticleCardHorizontal className='article2' post={posts[1]} onClick={onClick} />
                    <ArticleCardHorizontal className='article3' post={posts[2]} onClick={onClick} />
                    <ArticleCardHorizontal className='article4' post={posts[3]} onClick={onClick} />
                </div>
            </div>
            <div className='main-posts__articles mid'>
                <div className='mid-inner'>
                    <ArticleCard className='article1' post={posts[0]} onClick={onClick} />
                    <ArticleCard className='article2' post={posts[1]} onClick={onClick} />
                </div>
                <div  className='mid-inner'>
                    <ArticleCard className='article3' post={posts[2]} onClick={onClick} />
                    <ArticleCard className='article4' post={posts[3]} onClick={onClick} />
                </div>
            </div>
            <div className='main-posts__articles small'>
                <ArticleCardFixed className='article1 article-small' post={posts[0]} onClick={onClick} white={true} />
                <ArticleCardFixed className='article2 article-small' post={posts[1]} onClick={onClick} white={true} />
                <ArticleCardFixed className='article3 article-small' post={posts[2]} onClick={onClick} white={true} />
                <ArticleCardFixed className='article4 article-small' post={posts[3]} onClick={onClick} white={true} />
            </div>
        </div>
    )
}

export default MainPosts
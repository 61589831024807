import React from 'react'
import logo from '../media/logo.svg';
import logoLong from '../media/logo-long.svg';
import './Footer.css';
import { facebook_url, instagram_url, linkedin_url, youtube_url } from '../constants';
import {facebook_icon, instagram_icon, linkedin_icon, youtube_icon} from '../media/social-icons/social-icons'

const SocialMediaLink = ({ url, icon }) => {
    return (
        <div className='social-wrapper'>
            <a target='_blank' href={url}>
                <div className='white-background' />
                <img src={icon} alt='social media link' className='social-icon' />
            </a>
        </div>
    );
};

const Footer = () => {
  return (
    <div className='footer__section'>
        <img src={logoLong} alt='ReImagine PT' className='footer-logo-long' />
        <div className='footer-text'>
            <span className='footer-copyright-text'>Copyright © 2023 • </span>
            <span className='footer-ript-text'>ReImagine Physical Therapy</span>
        </div>
        <div className='footer-social-icons'>
            <SocialMediaLink icon={linkedin_icon} url={linkedin_url} />
            <SocialMediaLink icon={youtube_icon} url={youtube_url} />
            <SocialMediaLink icon={facebook_icon} url={facebook_url} />
            <SocialMediaLink icon={instagram_icon} url={instagram_url} />
        </div>
    </div>
  );
};

export default Footer
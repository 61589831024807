import { BASE_URL, urlPostfix } from './constants';

export const getCategoryFormat = (category) => {
    switch (true) {
        case category.includes("sports-and-recreation"):
            return "Sports & Recreation";
        case category.includes("fitness"):
            return "Fitness";
        case category.includes("rehab"):
            return "Rehab";
        case category.includes("health"):
            return "Health";
        default:
            return "N/A"; // Default color if none of the conditions are met
    }
};

export const postfixImageWidth = (url, postfixWidth) => {
    const urlTemArray = url.split('.');
    return urlTemArray[0] + "_w" + postfixWidth + "." + urlTemArray[1];
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export const stringToUrl = (inputString) => {
    return inputString.toLowerCase().replace(/ /g, '-');
};

export const getImageUrl = (imagePath) => {
    return `${BASE_URL}${imagePath}${urlPostfix}`;
};
import React from 'react'
import MainPosts from '../sections/MainPosts';
import LatestPosts from '../sections/LatestPosts';
import PopularPosts from '../sections/PopularPosts';
import NewsletterSignup from '../sections/NewsletterSignup'

const Blog = ({ posts, onClick }) => {

    const mainPosts = [];
    const latestPosts = [];
    posts.forEach(post => {
        // Check if the post is marked as main
        if (post.main) {
          // Check if we've already collected four main posts
          if (mainPosts.length < 4) {
            // If not, add it to the main posts
            mainPosts.push(post);
          } else {
            // If yes, add it to the latest posts
            latestPosts.push(post);
          }
        } else {
          // If the post is not marked as main, add it to the latest posts
          latestPosts.push(post);
        }
      });

    return (
        <div>
            <MainPosts posts={mainPosts} onClick={onClick} />
            <LatestPosts posts={latestPosts} title={'Latest Posts'} onClick={onClick} />
            <PopularPosts posts={posts.filter(entry => entry.popular === true)} background={true} onClick={onClick} />
            <NewsletterSignup />
        </div>
    )
}

export default Blog